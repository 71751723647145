import React from 'react'

function Routes() {
    return <></>
  }
 
  Routes.global = {
     url_login: 'https://gemcontsoft.com/apiGemcontsoft/auth/'
    //url_login: 'https://abogadooscarhenao.com/apiHenao/auth/'
  };
  
  export default Routes;

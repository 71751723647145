import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  modulo: "",
  deporte: "",
  subdeporte: "",
  pais: "",
  departamento: "",
  ciudad: ""
};

function Servicios () {
    const {user} = useContext(MyContext); 
 // const {rootState,logoutUser} = useContext(MyContext);
  //const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://henao.hoztcrea.com/apiHenao/servicios/servicios.php/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    name:'',
    nit:'',
    ciudad:'',
    licencia:'',
    email:'',
    clave:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("valorhora", frameworkSeleccionado.valorhora);

    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.valorhora= frameworkSeleccionado.valorhora;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("pais", frameworkSeleccionado.pais);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }


 /* const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      //sortDirection:"asc"
      
    },
    
    {
       
      title:"TITULO",
      field:"titulo1"
      
      
      
    },


    

    
    
  ];


  
    
  if(user)
        {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>




<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">SERVICIOS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<> <i class="fa fa-cog fa-2x"></i>  SERVICIOS</> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'build',
        tooltip: 'Editar servicios',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
 /*{
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }*/
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar el cliente {frameworkSeleccionado && frameworkSeleccionado.nombre}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar} style={{maxWidth:"800px"}}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">VER SERVICIOS</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <form method="post" action="https://henao.hoztcrea.com/apiHenao/servicios_img/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>
   <div className="row">

   <div class="col-12">
     <br/>
     <label>Titulo 1:</label>
     <br/>
     <input type="text"  placeholder="Titulo 1" className="form-control " id="titulo1" name="titulo1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo1}  />

     </div>

     <div class="col-12">
     <br/>
     <label>Titulo 2:</label>
     <br/>
     <input type="text"  placeholder="titulo2" className="form-control " id="titulo2" name="titulo2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo2}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " id="descripcion" name="descripcion" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion}  />

     </div>
   
   <center><div class="col-12">
   <br/>
     <label>Imagen principal:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen}     
     width="320px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 600px (ancho) x 369px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>



              <center><div class="col-12">
   <br/>
     <label>Imagen 1:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen_1}     
     width="120px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo :</label>
     <br/>
     <input type="text"  placeholder="titulo_1" className="form-control " id="titulo_1" name="titulo_1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo_1}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción :</label>
     <br/>
     <textarea rows={4}  placeholder="descripcion_1" className="form-control " id="descripcion_1" name="descripcion_1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion_1}  />

     </div>

<center>
     <div class="col-12">
     <br/>
     <label>-----------------------------------------------------------------------------------------------------</label>
     <br/>
      </div>
      </center>


     





      <center><div class="col-12">
   <br/>
     <label>Imagen 2:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen_2}     
     width="150px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>


      <div class="col-12">
     <br/>
     <label>Titulo :</label>
     <br/>
     <input type="text"  placeholder="titulo_2" className="form-control " id="titulo_2" name="titulo_2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo_2}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción :</label>
     <br/>
     <textarea rows={4}  placeholder="descripcion_2" className="form-control " id="descripcion_2" name="descripcion_2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion_2}  />

     </div>

<center>
     <div class="col-12">
     <br/>
     <label>-----------------------------------------------------------------------------------------------------</label>
     <br/>
      </div>
      </center>



      

      <center><div class="col-12">
   <br/>
     <label>Imagen 3:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen_3}     
     width="150px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto5" name="txtFoto5" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>


      <div class="col-12">
     <br/>
     <label>Titulo :</label>
     <br/>
     <input type="text"  placeholder="titulo_3" className="form-control " id="titulo_3" name="titulo_3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo_3}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción :</label>
     <br/>
     <textarea rows={4}  placeholder="descripcion_3" className="form-control " id="descripcion_3" name="descripcion_3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion_3}  />

     </div>

<center>
     <div class="col-12">
     <br/>
     <label>-----------------------------------------------------------------------------------------------------</label>
     <br/>
      </div>
      </center>


      <center><div class="col-12">
   <br/>
     <label>Imagen 4:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen_4}     
     width="150px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto6" name="txtFoto6" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>


      <div class="col-12">
     <br/>
     <label>Titulo :</label>
     <br/>
     <input type="text"  placeholder="titulo_4" className="form-control " id="titulo_4" name="titulo_4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo_4}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción :</label>
     <br/>
     <textarea rows={4}  placeholder="descripcion_4" className="form-control " id="descripcion_4" name="descripcion_4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion_4}  />

     </div>

<center>
     <div class="col-12">
     <br/>
     <label>-----------------------------------------------------------------------------------------------------</label>
     <br/>
      </div>
      </center>


      <center><div class="col-12">
   <br/>
     <label>Imagen 5:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen_5}     
     width="150px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto7" name="txtFoto7" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>


      <div class="col-12">
     <br/>
     <label>Titulo :</label>
     <br/>
     <input type="text"  placeholder="titulo_5" className="form-control " id="titulo_5" name="titulo_5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo_5}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción :</label>
     <br/>
     <textarea rows={4}  placeholder="descripcion_5" className="form-control " id="descripcion_5" name="descripcion_5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion_5}  />

     </div>

<center>
     <div class="col-12">
     <br/>
     <label>-----------------------------------------------------------------------------------------------------</label>
     <br/>
      </div>
      </center>

      <center><div class="col-12">
   <br/>
     <label>Imagen 6:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/servicios_img/Imagenes/"+frameworkSeleccionado.imagen_6}     
     width="150px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto8" name="txtFoto8" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>


      <div class="col-12">
     <br/>
     <label>Titulo :</label>
     <br/>
     <input type="text"  placeholder="titulo_6" className="form-control " id="titulo_6" name="titulo_6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo_6}  />

     </div>


     <div class="col-12">
     <br/>
     <label>Descripción :</label>
     <br/>
     <textarea rows={4}  placeholder="descripcion_6" className="form-control " id="descripcion_6" name="descripcion_6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion_6}  />

     </div>



     {/*<div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                <br/>
              </div>
       
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
</div> */}
        

     </div>




    
    
   
     <div style={{display:"none"}}>
<input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />
<input name="imagen"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen} autoComplete="off" readOnly />
     <input name="imagen_1"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen_1} autoComplete="off" readOnly /> 
     <input name="imagen_2"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen_2} autoComplete="off" readOnly />
     <input name="imagen_3"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen_3} autoComplete="off" readOnly />
     <input name="imagen_4"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen_4} autoComplete="off" readOnly />
     <input name="imagen_5"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen_5} autoComplete="off" readOnly />
     <input name="imagen_6"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen_6} autoComplete="off" readOnly />
     

  </div>

   </div>
   <br/>

  <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Actualizar Cliente </button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar </button>
    </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">CREAR CLIENTE</font></ModalHeader>
 <ModalBody>
 <div className="form-group">
   <form method="post" action="https://henao.hoztcrea.com/apiHenao/clientes_img/index_agregar.php" target="_parent" name="reset2" encType='multipart/form-data'  noValidate>
   <div className="row">
   
  
     <div class="col-12">
     <br/>
     <label>Nombre del cliente:</label>
     <br/>
     <input type="text"  placeholder="Nombre cliente" className="form-control " id="nombre" name="nombre" onChange={handleChange} />

     </div>

     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 795px (ancho) x 380px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar cliente</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      
     {/*<div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                <br/>
              </div>
       
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
</div> */}
        

     </div>


     
     <div style={{display:"none"}}>
<input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />

  </div>

   </div>
   <br/>

  <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Crear Cliente </button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
  {/* <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}*/}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(!user){
    return"";
}


}

export default Servicios

import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';



function Litigios() {
 const [descripcion1, setValue] = useState('');
 console.log(descripcion1 + " textInput");

 const {user} = useContext(MyContext); 
   
  const baseUrlAd="https://henao.hoztcrea.com/apiHenao/litigios/litigios.php/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    setValue:'',
    descripcion_1:'',
    nit:'',
    ciudad:'',
    licencia:'',
    email:'',
    clave:'',
  });


     
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

 
  useEffect(()=>{
    

   // const interval = setInterval(() => {
      
      peticionGet();
       
     //}, 2000);
 
   //return () => setInterval(interval);
 
   },[])

  
  
  const columnas =[
    {
      title:"ID",
      field: "id",
      //sortDirection:"asc"
      
    },
    
    {
       
      title:"TITULO",
      field:"titulo1"
      
      
      
    },


    

    
    
  ];


  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    //'image',
    'color',
    'size',
    //'video',
    'align',
    //'background',
    'direction',
    'code-block',
    'code',
    ];
  
    
  
    
  if(user)
        {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>




<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">LITIGIOS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<> <i class="fa fa-cog fa-2x"></i>  LITIGIOS</> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'build',
        tooltip: 'Editar litigios',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
 /*{
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }*/
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar el cliente {frameworkSeleccionado && frameworkSeleccionado.nombre}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar} style={{maxWidth:"800px"}}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">VER LITIGIOS</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <form method="post" action="https://henao.hoztcrea.com/apiHenao/litigios_img/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>
   <div className="row">


  
   
   <center><div class="col-12">
   <br/>
     <label>Imagen logo:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen1}     
     width="120px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 512px (ancho) x 512px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
    </div>


    <div class="col-12">
     <br/>
     <label>Titulo 1:</label>
     <br/>
     <input type="text"  placeholder="Titulo 1" className="form-control " id="titulo1" name="titulo1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo1}  />

     </div>




     
     <center><div class="col-12">
   <br/>
     <label>Imagen 1:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen2}     
     width="140px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 643px (ancho) x 546px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>


      <div class="col-12">
     <br/>
     <label>Titulo 2:</label>
     <br/>
     <input type="text"  placeholder="titulo2" className="form-control " id="titulo2" name="titulo2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo2}  />
      <br/>
     </div>



    

     <div class="col-12" style={{backgroundColor:'#FFCEC3'}}>
        <br/>
        <label>Descripción actual</label>
        <ReactQuill  theme="snow"  value={frameworkSeleccionado.descripcion1} readOnly
  />
   <br/>
</div>


  <div class="col-12">
        <br/>
        <label>Cambiar descripción actual</label>
        <ReactQuill theme="snow" value={descripcion1}  onChange={setValue}
modules={modules} formats={formats} />


   <br/>
</div> 




     

     
     <center><div class="col-12">
   <br/>
     <label>Imagen 3:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen3}     
     width="100px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 89px (ancho) x 86px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo3}  />
  <br/>
     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " name="descripcion2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion2} /> 
  <br/>
     </div>
  





     <center><div class="col-12">
   <br/>
     <label>Imagen 4:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen4}     
     width="100px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 89px (ancho) x 86px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto5" name="txtFoto5" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo4}  />
  <br/>
     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " name="descripcion3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion3} /> 
  <br/>
     </div>






  



     <center><div class="col-12">
   <br/>
     <label>Imagen 5:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen5}     
     width="100px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 89px (ancho) x 86px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto6" name="txtFoto6" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo5}  />
  <br/>
     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " name="descripcion4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion4} /> 
  <br/>
     </div>





     <center><div class="col-12">
   <br/>
     <label>Imagen 6:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen6}     
     width="100px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 89px (ancho) x 86px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto7" name="txtFoto7" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo6}  />
  <br/>
     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " name="descripcion5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion5} /> 
  <br/>
     </div>



     <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo7" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo7}  />
  <br/>
     </div>





     <center><div class="col-12">
   <br/>
     <label>Imagen 7:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen7}     
     width="100px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 89px (ancho) x 86px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto8" name="txtFoto8" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo8" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo8}  />
  <br/>
     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " name="descripcion10" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion10} /> 
  <br/>
     </div>






     <center><div class="col-12">
   <br/>
     <label>Imagen 8:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/litigios_img/Imagenes/"+frameworkSeleccionado.imagen8}     
     width="100px" />
    
     <br/>
     </div>

 

     
     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 89px (ancho) x 86px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>
        </center> 

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar imagen</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto9" name="txtFoto9" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      </div>

      <div class="col-12">
     <br/>
     <label>Titulo:</label>
     <br/>
     <input type="text"  placeholder="titulo" className="form-control " name="titulo9" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.titulo9}  />
  <br/>
     </div>


     <div class="col-12">
     <br/>
     <label>Descripción:</label>
     <br/>
     <textarea rows={4}  placeholder="descripción" className="form-control " name="descripcion11" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.descripcion11} /> 
  <br/>
     </div>
     


     </div>



     <div style={{display:"none"}}>
<input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />
     <input name="imagen1"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen1} autoComplete="off" readOnly /> 
     <input name="imagen2"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen2} autoComplete="off" readOnly />
     <input name="imagen3"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen3} autoComplete="off" readOnly />
     <input name="imagen4"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen4} autoComplete="off" readOnly />
     <input name="imagen5"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen5} autoComplete="off" readOnly />
     <input name="imagen6"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen6} autoComplete="off" readOnly />
     <input name="imagen7"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen7} autoComplete="off" readOnly />
     <input name="imagen8"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.imagen8} autoComplete="off" readOnly />

     { descripcion1 !=""
     ?
     <input name="descripcion1"  className="form-control" type="text" placeholder="I" value={descripcion1} autoComplete="off" readOnly />
    :
    <input name="descripcion1"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado.descripcion1} autoComplete="off" readOnly />
}   

  </div>


   <br/>

  <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Actualizar Cliente </button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar </button>
    </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">CREAR CLIENTE</font></ModalHeader>
 <ModalBody>
 <div className="form-group">
   <form method="post" action="https://henao.hoztcrea.com/apiHenao/clientes_img/index_agregar.php" target="_parent" name="reset2" encType='multipart/form-data'  noValidate>
   <div className="row">
   
  
     <div class="col-12">
     <br/>
     <label>Nombre del cliente:</label>
     <br/>
     <input type="text"  placeholder="Nombre cliente" className="form-control " id="nombre" name="nombre" onChange={handleChange} />

     </div>

     <div class="col-12">
     <br/>

     <h6>Nota: La resolución recomendada ser de 795px (ancho) x 380px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar cliente</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
      
     {/*<div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                <br/>
              </div>
       
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
</div> */}
        

     </div>


     
     <div style={{display:"none"}}>
<input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />

  </div>

   </div>
   <br/>

  <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Crear Cliente </button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
  {/* <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}*/}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(!user){
    return"";
}


}

export default Litigios

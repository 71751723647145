import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'
import {Link} from 'react-router-dom';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  modulo: "",
  deporte: "",
  subdeporte: "",
  pais: "",
  departamento: "",
  ciudad: ""
};

function Blog () {
    const [descriptionc, setValue] = useState('');
    console.log(descriptionc + " textInput")
    const {user} = useContext(MyContext); 
 // const {rootState,logoutUser} = useContext(MyContext);
  //const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://henao.hoztcrea.com/apiHenao/blog/blog.php/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    descriptionc:'',
    name:'',
    nit:'',
    ciudad:'',
    licencia:'',
    email:'',
    clave:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("valorhora", frameworkSeleccionado.valorhora);

    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.valorhora= frameworkSeleccionado.valorhora;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("pais", frameworkSeleccionado.pais);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }


 /* const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      //sortDirection:"asc"
      
    },
    
    {
       
      title:"TITULO",
      field:"titulo"
      
      
      
    },

    {
       
        title:"DESCRIPCIÓN CORTA",
        field:"descripcion"
        
        
        
      },


    
      {
       
        title:"FECHA",
        field:"fecha"
        
        
        
      }
    
    
  ];



  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    //'image',
    'color',
    'size',
    //'video',
    'align',
    //'background',
    'direction',
    'code-block',
    'code',
    ];
  
    
  if(user)
        {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>




<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">BLOG</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-primary" onClick={()=>abrirCerrarModalInsertar()}> <i class="fa fa-cog fa-2x"></i>  AGREGAR ENTRADA - BLOG</button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

  /*  {
        icon : 'build',
        tooltip: 'Editar cliente',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },*/
    
 {
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar la entrada{frameworkSeleccionado && frameworkSeleccionado.nombre}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar} style={{maxWidth:"800px"}}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">VER ENTRADA - BLOG</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <form method="post" action="https://henao.hoztcrea.com/apiHenao/blo_img/index.php" target="_parent" name="reset2" encType='multipart/form-data'  noValidate>
   <div className="row">
   
  
     <div class="col-12">
     <br/>
     <label>Titulo de entrada:</label>
     <br/>
     <input type="text"  placeholder="Titulo" className="form-control" value={frameworkSeleccionado.titulo} id="titulo" name="titulo" onChange={handleChange} />
     <br/>
     </div>


     <div class="col-12">

<label>Descripción corta de entrada:</label>
<br/>
      <textarea rows="4" className="form-control" value={frameworkSeleccionado.descripcion}  name="descripcion" onChange={handleChange}
      
        /> 
        <br/>
  </div>

 

     <div class="col-12">
     <br/>

     <h6>Nota: La resolución para la imagen de portada es de 300px (ancho) x 160px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>

    <div class="col-12">

     <br/>
    
    <center><div class="col-12">
     <label>Imagen de portada:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/blo_img/Imagenes/"+frameworkSeleccionado.imagen}     
     width="320px" />
    
     <br/>
     </div></center>
     <div class="form-group">

                <label class="form-label" for="customFile">Imagen de portada</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} /> 
                
              </div>

        </div>      

              <div class="col-12">
     <br/>
     <label>Titulo 2:</label>
     <br/>
     <input type="text"  placeholder="Titulo" className="form-control " value={frameworkSeleccionado.titulo1} id="titulo1" name="titulo1" onChange={handleChange} />
     <br/>
     </div>


     <center><div class="col-12">
     <label>Imagen articulo:</label>
     <br/>
    
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://henao.hoztcrea.com/apiHenao/blo_img/Imagenes/"+frameworkSeleccionado.imagen2}     
     width="320px" />
    
     <br/>
     </div></center>


     <div class="col-12">
     <br/>

     <h6>Nota: La resolución de la imagen de contenido recomendada ser de 795px (ancho) x 380px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Imagen de contenido</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                
              </div>

        </div>   

        <div class="col-12">
     <br/>
     <label>Titulo 3:</label>
     <br/>
     <input type="text"  placeholder="Titulo" className="form-control " value={frameworkSeleccionado.titulo2} id="titulo2" name="titulo2" onChange={handleChange} />
     <br/>
     </div>


     <div class="col-12">
   <ReactQuill theme="snow" value={frameworkSeleccionado.descriptionc} onChange={setValue} readOnly
   
   />
   <br/>
</div>

     <div class="col-12">
   <ReactQuill theme="snow" value={descriptionc}  onChange={setValue}
   modules={modules} formats={formats}
   />
   <br/>
</div>


     <div class="col-12">

<label>Autor:</label>
<br/>
      <input type="text" className="form-control" value={frameworkSeleccionado.creado}  name="creado" onChange={handleChange}
      
        />
        <br/>
  </div>



     



      
     {/*<div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                <br/>
              </div>
       
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
</div> */}
        

     </div>

    

     <div style={{display:"none"}}>
<input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />
{ descriptionc !=""
     ?
     <input name="descriptionc"  className="form-control" type="text" placeholder="I" value={descriptionc} autoComplete="off" readOnly />
    :
    <input name="descriptionc"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado.descriptionc} autoComplete="off" readOnly />
}   

  </div>


   <br/>

  <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Crear Entrada </button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar </button>
    </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar} style={{maxWidth:"800px"}}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">CREAR ENTRADA - BLOG</font></ModalHeader>
 <ModalBody>
 <div className="form-group">
   <form method="post" action="https://henao.hoztcrea.com/apiHenao/blo_img/index_agregar.php" target="_parent" name="reset2" encType='multipart/form-data'  noValidate>
   <div className="row">
   
  
     <div class="col-12">
     <br/>
     <label>Titulo de entrada:</label>
     <br/>
     <input type="text"  placeholder="Titulo" className="form-control " id="titulo" name="titulo" onChange={handleChange} />
     <br/>
     </div>


     <div class="col-12">

<label>Descripción corta de entrada:</label>
<br/>
      <textarea rows="4" className="form-control"  name="descripcion" onChange={handleChange}
      
        /> 
        <br/>
  </div>

     <div class="col-12">
     <br/>

     <h6>Nota: La resolución para la imagen de portada es de 300px (ancho) x 160px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Imagen de portada</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
                
              </div>

        </div>      

              <div class="col-12">
     <br/>
     <label>Titulo 2:</label>
     <br/>
     <input type="text"  placeholder="Titulo" className="form-control " id="titulo1" name="titulo1" onChange={handleChange} />
     <br/>
     </div>



     <div class="col-12">
     <br/>

     <h6>Nota: La resolución de la imagne de contenido recomendada ser de 795px (ancho) x 380px (alto) y el peso recomendado debe ser máximo de 300kb </h6>

        </div>

    <div class="col-12">

     <br/>
    
    
     <div class="form-group">

                <label class="form-label" for="customFile">Imagen de contenido</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                
              </div>

        </div>   

        <div class="col-12">
     <br/>
     <label>Titulo 3:</label>
     <br/>
     <input type="text"  placeholder="Titulo" className="form-control " id="titulo2" name="titulo2" onChange={handleChange} />
     <br/>
     </div>


     <div class="col-12">
   <ReactQuill theme="snow" value={descriptionc}  onChange={setValue}
   modules={modules} formats={formats}
   />
   <br/>
</div>


     <div class="col-12">

<label>Autor:</label>
<br/>
      <input type="text" className="form-control"  name="creado" onChange={handleChange}
      
        />
        <br/>
  </div>



     



      
     {/*<div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                <br/>
              </div>
       
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
</div> */}
        

     </div>


     
   
     <div style={{display:"none"}}>
<input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />
<input name="descriptionc"  className="form-control" type="text" placeholder="I" value={descriptionc} autoComplete="off" readOnly />

  </div>


   <br/>

  <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Crear Entrada </button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
  {/* <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}*/}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(!user){
    return"";
}


}

export default Blog

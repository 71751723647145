import './index.css';
import {HashRouter as Router, Routes, Route} from "react-router-dom";

import MyContextProvider from './components/login/MyContext';
import history from './components/login/history';

import Inicio from './components/views/inicio/Inicio';
import Noticias from './components/views/configuracion/Noticias';

import Clientes from './components/views/configuracion/Clientes';
import Blog from './components/views/configuracion/Blog';

import Servicios from './components/views/configuracion/Servicios';
import Asesorias from './components/views/configuracion/Asesorias';
import Litigios from './components/views/configuracion/Litigios';
import Negociacion from './components/views/configuracion/Negociacion';
import Auditorias from './components/views/configuracion/Auditorias';
import Capacitaciones from './components/views/configuracion/Capacitaciones';
import Seguridad from './components/views/configuracion/Seguridad';
import Somos from './components/views/configuracion/Somos';

function App() {
  return (
    <MyContextProvider>
    <Router>
    <Inicio/>
    <Routes>

    <Route history={history}></Route>
    
   
    <Route path="/Noticias" element = {<Noticias/>}></Route>
    <Route path="/Clientes" element = {<Clientes/>}></Route>
    <Route path="/Blog" element = {<Blog/>}></Route>
    <Route path="/Servicios" element = {<Servicios/>}></Route>
    <Route path="/Asesorias" element = {<Asesorias/>}></Route>
    <Route path="/Litigios" element = {<Litigios/>}></Route>
    <Route path="/Negociacion" element = {<Negociacion/>}></Route>
    <Route path="/Auditorias" element = {<Auditorias/>}></Route>
    <Route path="/Capacitaciones" element = {<Capacitaciones/>}></Route>
    <Route path="/Seguridad" element = {<Seguridad/>}></Route>
    <Route path="/Somos" element = {<Somos/>}></Route>

    <Route path="/" element = {<Noticias/>}></Route>

    </Routes>
    </Router>
    </MyContextProvider>
  );
}

export default App;
